



































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import AccessImage from './accessImage.vue'
import { getWidth } from '@/utils/dom'
import { getParent } from '@/utils/getParents'
import store, { actionTypes } from '@/store'
import { treeFind } from 'operation-tree-node'

@Component({ components: { FeatureImg, AccessImage } })
export default class PersonVisit extends Vue {
  @Prop({ required: true }) trip: AnyObj

  tripLeft = false
  tripRight = false
  hoverIndex: number | null = null
  hoverImageIndex: number | null = null
  areaImageDetails: any[] = []
  loading = false
  areaName = ''
  activeIndex = 0
  showFlag = false
  imageDetail: any = {}

  @Watch('trip')
  watchTrip() {
    this.initTrip()
  }

  async getAreaName(areaId: string) {
    const area: any = treeFind(this.treeList, node => node.id === areaId)
    if (area) return area.name
    return null
  }

  async mounted() {
    this.treeList = await store.dispatch(actionTypes.FETCH_BASE_AREAS)
    this.initTrip()
  }
  initTrip() {
    if (this.trip.trace) {
      this.trip.trace.map(async (i: any) => {
        return (i.areaName = await this.getAreaName(i.areaId))
      })
      const timeLine: any = this.$refs.timeLine
      const width = this.computedWidth()
      timeLine.style.width = width + 'px'
      this.isLightTrip()
      setTimeout(() => {
        this.filterTrip()
      }, 500)
    }
  }

  imageHover(index: number, type: boolean) {
    this.hoverImageIndex = type ? index : null
  }
  imageClick(obj: AnyObj) {
    this.showFlag = true
    const { areaId } = obj
    let areaName = '-'
    if (areaId) {
      const list = getParent(this.treeList, areaId, this.root, true)
      areaName = list.map(i => i.name).join('/')
    }
    this.imageDetail = { ...obj, areaName }
  }
  async clickAccessPoint(item: any, i: number) {
    if (i !== this.activeIndex) {
      this.activeIndex = i
      this.areaName = item.areaName
      await this.getAreaImageUrl(item.captureId)
    }
  }
  async filterTrip() {
    // 获取第一个区域点
    this.areaName = this.trip.trace[0].areaName
    this.activeIndex = 0
    await this.getAreaImageUrl(this.trip.trace[0].captureId)
  }
  async getAreaImageUrl(captureId: number) {
    this.loading = true
    const resp = await this.$api.v2.getCapturesSearch({
      ids: [captureId.toString()]
    })
    this.loading = false
    if (resp.data && resp.data.data) this.areaImageDetails = resp.data.data
  }

  computedImageWidth() {
    const m = this.areaImageDetails.length
    return m * 190 - 60
  }
  autoMove() {
    let m = 0,
      n = 0
    const trip = this.trip.trips
    for (let i = 0; i < trip.length; i++) {
      if (i > 0) n = n + trip[i - 1].trace.length
      for (let j = 0; j < trip[i].trace.length; j++) {
        if (trip[i].trace[j].path.length > 0) {
          n++
          if (trip[i].trace[j].path[0].status === undefined) {
            m = i
            n = j + n
            const rate = m * (140 + 60) + n * 134
            this.$nextTick(() => {
              if (rate > 134 * 2) {
                this.move(rate, 'autoMove')
              }
            })
            return
          }
        }
      }
    }
  }

  computedWidth() {
    return 140 + 60 + this.trip.trace.length * 134
  }

  isLightTrip() {
    const timeLine = this.$refs.timeLine as HTMLElement
    const width = getWidth(timeLine)
    const wrap = timeLine.parentElement
    const wrapWidth = getWidth(wrap)
    const canLeft = width - wrapWidth
    if (canLeft < 0) {
      this.tripLeft = false
      this.tripRight = false
    } else {
      this.tripLeft = false
      this.tripRight = true
    }
  }

  move(rate: number, type: string) {
    const timeLine = this.$refs.timeLine as HTMLElement
    const left = timeLine.offsetLeft
    const width = getWidth(timeLine)
    const wrap = timeLine.parentElement
    const wrapWidth = getWidth(wrap)
    const canLeft = width - wrapWidth
    if (canLeft < 0) return // 不能移动
    let moved
    if (type === 'autoMove') {
      moved = -rate
    } else {
      moved = left + rate * wrapWidth
    }
    if (moved >= 0) {
      moved = 0 // 点击左侧按钮直到不能移动的时候，此时左侧按钮不亮
      this.tripLeft = false
      this.tripRight = true
    } else if (-moved >= canLeft) {
      // 点击右侧按钮直到不能移动的时候，此时右侧按钮不亮
      moved = -canLeft
      this.tripRight = false
      this.tripLeft = true
    } else {
      this.tripRight = true
      this.tripLeft = true
    }
    if (Math.abs(Math.abs(left) - Math.abs(moved)) > 60) {
      timeLine.style.left = `${moved}px`
      timeLine.animate([{ left: `${left}px` }, { left: `${moved}px` }], 700)
    }
  }
}
