






















































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import PersonVisit from './personVisit.vue'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import { parseGender, repoType, getAttr, getDuration, timeType } from '@/utils'
import addPersonal from '@/components/common/AddPersonnel/index.vue'
import CopyText from '@/components/common/CopyText.vue'
import photoDialog from '@/components/common/photoDialog.vue'
import TagsDialog from '@/components/common/TagsDialog.vue'
import { variables } from '@/libs/theme'
import { backTop } from '@/utils/dom'
import SecurityText from '@/components/common/SecurityText/index.vue'
import store, { actionTypes } from '@/store'
import { treeFind } from 'operation-tree-node'

@Component({
  components: {
    FeatureImg,
    PersonVisit,
    addPersonal,
    CopyText,
    photoDialog,
    TagsDialog,
    SecurityText
  }
})
export default class PersonVisitDetail extends Vue {
  @Prop({ required: false, default: () => ({}) }) person: AnyObj
  @Prop({ required: false, default: false }) showDetail: boolean
  @Prop({ required: false }) screenCon: any
  @Prop({ required: false, default: false }) drawerIndex: number
  areaId: string | null = null
  info: AnyObj = {}
  personId = ''
  treeList: any[] = []
  moreLoading = false
  noMore = false
  resultList: any[] = []
  finished = true

  offset = 0
  accessCount = 0
  isShowTop = false
  noData = require('@/assets/images/dashboard/noData.png')
  tabLoading = true
  infoLoading = false
  startDate = null
  endDate = null
  areaIds: any[] = []
  areaName: any[] = []
  status = true
  areaList: any[] = []
  errorloading = false
  showDetailDialog = false
  activeColor = variables.colorPrimary
  inActiveColor = variables.greyColor
  mostFrequencyArea: string | null = null
  duration = null

  get personType() {
    return getAttr(this.info, 'tags[0].type', false)
  }

  get registered() {
    return this.info && this.info.tags
  }

  get user() {
    return this.$store.state.user || {}
  }
  get timeType() {
    return timeType
  }

  get rootConfig() {
    return this.$store.state.config?.root
  }

  // 调用base api的临时方案，之后base api中areaId从header中取后，删掉这块的逻辑即可
  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  async mounted() {
    this.showDetailDialog = this.showDetail
    this.$nextTick(() => {
      this.status = this.drawerIndex === 1
    })

    this.personId = this.person.id
    this.initPage()
  }

  destroyed() {
    this.$message.closeAll()
  }

  async toPersonDeatil(val: AnyObj) {
    if (val.id) this.$emit('toPersonDeatil', val)
  }

  async initPage() {
    this.$message.closeAll()
    this.treeList = []
    const areas = await store.dispatch(actionTypes.FETCH_BASE_AREAS)

    this.treeList = areas
    await this.getPersonInfo() // 人员详细信息
    await this.getPersonDetail(this.personId)
    this.resultList = []
    this.tabLoading = true
    this.offset = 0
    this.$nextTick(() => {
      this.resetSearchVal(this.screenCon !== undefined && this.screenCon !== null && this.status)
      this.getTraceByPid() // 人次卡片
    })
  }
  getMostFrequencyArea(data: AnyObj) {
    if (data) {
      let path = ''
      if (data.path && data.path.length > 0) {
        data.path.forEach((item: AnyObj) => {
          path = path + item.name + '-'
        })
      }
      path = path + data.name
      return path
    }
    return null
  }
  async getPersonDetail(personId: string) {
    this.infoLoading = true
    const startTime = new Date('1970-01-01').toISOString()
    const endTime = new Date().toISOString()
    const resp1 = (
      await this.$api.base.getRadarData(
        `${this.root}?personId=${personId}&&startTime=${startTime}&&endTime=${endTime}`
      )
    ).data
    const resp2 = (
      await this.$api.base.mostFrequencyArea(personId, {
        params: { scopeId: this.root }
      })
    ).data
    const res = await Promise.all([resp1, resp2])
    this.duration = res[0].data ? res[0].data.avgStayTime : null
    this.mostFrequencyArea = this.getMostFrequencyArea(res[1].data)
    this.infoLoading = false
  }
  async getAreaName() {
    if (this.status) {
      const allAreaId: AnyObj[] = await store.dispatch(actionTypes.FETCH_BASE_AREAS)
      if (this.areaIds.length > 0) {
        this.areaIds.map(async item => {
          const area = treeFind(allAreaId, node => node.id === item)
          if (area) {
            this.areaName.push(area.name)
            this.areaName = Array.from(new Set(this.areaName))
          }
        })
      }
    }
  }
  backToTop() {
    const detail = this.$refs.detailNode as HTMLElement
    backTop(detail)
  }

  async resetSearchVal(val: boolean) {
    this.areaList = []
    if (val) {
      this.areaIds = this.screenCon.areaIds
      this.startDate = this.screenCon.date[0]
      this.endDate = this.screenCon.date[1]
      if (!this.areaIds.includes(this.root)) {
        // 不包含scope就进行处理,
        this.areaIds.forEach(obj => {
          this.getSearchArea(this.treeList, obj)
        })
      }
    } else {
      this.areaIds = [this.root]
      this.startDate = null
      this.endDate = null
    }
    this.getAreaName()
  }

  switchChange(val: boolean) {
    this.tabLoading = true
    this.offset = 0
    this.noMore = false
    this.resultList = []
    this.resetSearchVal(val)
    this.getTraceByPid()
  }

  getSearchArea(tree: AnyObj[], id: string) {
    const getArea = (n: any[]) => {
      n.forEach(item => {
        this.areaList.push(item.id)
        if (item.children) {
          getArea(item.children)
        }
      })
    }

    tree.forEach(item => {
      if (item.id === id) {
        this.areaList.push(item.id)
        if (item.children) {
          getArea(item.children)
        }
        return
      }
      if (item.children) {
        this.getSearchArea(item.children, id)
      }
    })
  }

  async getTraceByPid() {
    const params = {
      limit: 5,
      offset: this.offset,
      startTime: this.startDate,
      endTime: this.endDate,
      areaIds: this.areaIds,
      order: ':asc',
      scopeId: this.root
    }
    let resp
    try {
      this.errorloading = false
      resp = await this.$api.v2.getPersonTraceApi(this.personId, params)
      if (resp.data && resp.data.code === 0) {
        this.finished = true
        if (this.offset + 5 >= resp.data.count) {
          this.noMore = true
        }
        this.resultList = [...this.resultList, ...resp.data.data]
      }
    } catch (e) {
      this.finished = true
      this.moreLoading = false
      this.tabLoading = false
      this.errorloading = true
      this.offset = this.offset - 5
    } finally {
      this.finished = true
      this.moreLoading = false
      this.tabLoading = false
    }
  }

  boxScroll() {
    const box = this.$refs.detailNode as Element
    if (!box) return false
    const m = box.scrollHeight - box.scrollTop - box.clientHeight
    if (m > -2 && m < 2 && box.scrollTop !== 0 && this.finished) {
      this.finished = false
      if (!this.noMore && this.resultList.length > 0) {
        this.moreLoading = true
        setTimeout(() => {
          this.offset = this.offset + 5
          this.getTraceByPid()
        }, 1000)
      }
    }
    if (box.scrollTop > document.body.clientHeight) {
      this.isShowTop = true
    } else {
      this.isShowTop = false
    }
  }

  handleRegister(obj: AnyObj) {
    ;(this.$refs.addPersonalNode as Vue).open('detailAdd', obj)
  }

  async clickEdit(id: string) {
    const res = await this.$api.base.getPersonSingle(id)
    ;(this.$refs.addPersonalNode as Vue).open('edit', res.data.data)
  }

  clickPhotoManage(row: AnyObj) {
    ;(this.$refs.photoDialogNode as Vue).open(row)
  }

  clickEditTgs(row: AnyObj) {
    ;(this.$refs.editTagsNode as Vue).open('editTags', row)
  }

  async clickDelete(id: string) {
    this.$confirm('人员一经删除无法恢复，是否确定删除？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      cancelButtonClass: 'message-cancel-btn'
    })
      .then(async () => {
        const resp = await this.$api.base.getPersonsDelete(id)
        if (resp.status && resp.status === 204) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getPersonInfo()
        }
      })
      .catch(() => {
        this.$message({
          message: '已取消删除',
          type: 'info'
        })
      })
  }

  getAttr(obj: any, props: string, def: boolean) {
    return getAttr(obj, props, def)
  }

  parseGender(g: string) {
    return parseGender(g)
  }

  repoType(type: string) {
    return repoType(type)
  }

  getDuration(seconds: number, type: timeType) {
    let res: string | undefined = ''
    if (seconds === undefined) {
      res = 0 + '秒'
    } else {
      res = getDuration(seconds, type)
    }
    return res
  }

  async getPersonInfo() {
    this.infoLoading = true
    const p1 = await this.$api.base.getPersonInfo(this.personId)
    this.infoLoading = false
    if (p1.data && p1.data.data) {
      this.info = p1.data.data
    }
  }
}
