










































































































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import RadioDialog from '@/components/common/RadioDialog/index.vue'
import pagination from '@/components/common/pagination/index.vue'
import CopyText from '@/components/common/CopyText.vue'
import pageTitle from '@/components/common/pageTitle.vue'
import InputSearch from '@/components/common/InputSearch.vue'
import AccessImage from '@/components/dashboard/AccessImage/index.vue'
import { areaPath } from '@/utils/getAreaPath'
import SecurityText from '@/components/common/SecurityText/index.vue'
import { tableScrollTop } from '@/utils/dom'
import { variables } from '@/libs/theme'
import { actionTypes } from '@/store'

@Component({
  name: 'detailPage',
  components: {
    FeatureImg,
    pagination,
    CopyText,
    pageTitle,
    RadioDialog,
    InputSearch,
    AccessImage,
    SecurityText
  }
})
export default class DetialPage extends Vue {
  @Ref('paginationNode') readonly paginationNode: pagination
  @Ref('exportDialog') readonly exportDialog: RadioDialog
  @Ref('searchNode') readonly searchNode: InputSearch

  private shadow9Color = variables.shadow9Color
  private query = {
    limit: 20,
    offset: 0
  }
  private showFlag = false
  private visible = false
  private sels: any[] = []
  private personList: any = []
  private loading = false
  private noDataImg = require('@/assets/images/dashboard/noData.png')
  private filterData: AnyObj = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')]
  }
  private searchPar: any = null
  private personImg: any[] = [
    { name: '选择证件图片', url: null, EqualWH: true, BigPicture: false },
    { name: '选择核验图片', url: null, EqualWH: true, BigPicture: true, Typesetting: 'vertical' }
  ]
  private person: any = {}

  private verificationOptions = [
    { value: 1, label: '通过' },
    { value: 0, label: '未通过' }
  ]

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig ? this.rootConfig.id : this.$store.state.user.scope
  }

  get isRootAccount() {
    return this.root === this.$store.state.user.scope
  }

  get user() {
    return this.$store.state.user || {}
  }
  get currentAreaId() {
    return this.$store.state.areaId
  }

  @Watch('visible')
  watchVisible(visible: boolean) {
    if (visible) {
      if (this.personList.length) return
      this.getList(this.query.limit, 0)
      this.$store.dispatch(actionTypes.FETCH_BASE_AREAS)
    }
  }

  deactivated() {
    this.showFlag = false
    this.visible = false
  }

  public show(visible = true) {
    this.visible = visible
  }

  private pictureCallback(url: string) {
    this.show(false)
    this.$emit('change', { ...this.person, url })
  }

  private pageChange(data: AnyObj) {
    this.query.limit = data.pageSize
    this.query.offset = (data.pageNo - 1) * data.pageSize
    this.getList(this.query.limit, this.query.offset)
  }
  async getList(pageSize = 20, offset = 0) {
    this.loading = true
    try {
      const resp = await this.getVerifications(pageSize, offset)
      if (resp.data && resp.data.data) {
        const { data, count } = resp.data
        this.personList = data.map((item: AnyObj) => {
          Object.assign(item, areaPath(item.areas))
          return item
        })
        this.paginationNode.init({ total: count ? count : 0 })
        tableScrollTop()
      }
    } finally {
      this.loading = false
    }
  }
  private async getVerifications(pageSize = 20, offset = 0) {
    const val = this.filterData
    const [startTime, endTime] = val.dateRange
    const par: AnyObj = {
      startTime,
      endTime,
      limit: pageSize,
      offset: offset,
      areaIds: val.areaIds && val.areaIds.length > 0 ? val.areaIds : [this.root]
    }
    if (val.result || val.result === 0) {
      Object.assign(par, { result: !!val.result })
    }
    if (val.name && !val.name.match(/^[ ]*$/)) {
      Object.assign(par, { name: val.name })
    }
    this.searchPar = par
    return this.$api.base.verificationsSearch(par)
  }

  private handleFilter() {
    this.getList(this.query.limit, 0)
    this.paginationNode.initPage({ current: 1 })
  }

  private seePassImg(obj: AnyObj) {
    this.person = obj
    this.showFlag = true
    this.personImg[0].url = obj.cardUrl
    const url = obj.faceUrl || obj.sceneUrl
    const EqualWH = !!obj.faceUrl
    Object.assign(this.personImg[1], { url, EqualWH })
  }

  private selsChange(val: any) {
    this.sels = val
  }
}
