



















import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import PersonDrawerDetail from './detail.vue'

@Component({
  name: 'drawer',
  components: {
    PersonDrawerDetail
  }
})
export default class PersonDetail extends Vue {
  @Prop({ default: false }) private showDetail: boolean
  @Prop({ default: '' }) private person: string
  private isFullScreen = true
  private showDialog = false

  deactivated() {
    this.$emit('close')
  }

  mounted() {
    this.showDialog = this.showDetail
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  }
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }

  handelClose() {
    this.$emit('close')
  }

  private handleResize() {
    const width = document.documentElement.clientWidth
    if (width < 1366) {
      this.isFullScreen = false
    } else {
      this.isFullScreen = true
    }
  }
}
