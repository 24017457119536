







































































import { Vue, Component, Prop } from 'vue-property-decorator'
import PersonVisit from './personVisit.vue'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import { backTop } from '@/utils/dom'
import moment from 'moment'

@Component({
  components: {
    FeatureImg,
    PersonVisit
  }
})
export default class PersonVisitDetail extends Vue {
  @Prop({ required: false, default: () => ({}) }) person: AnyObj
  resultList: any[] = []
  isShowTop = false
  noData = require('@/assets/images/dashboard/noData.png')
  selectDate = null
  loading = false

  private pickerOptions = {
    disabledDate: (date: any) => {
      return (
        moment(date).startOf('day').isAfter(moment()) ||
        this.person.access.filter(
          (item: any) =>
            moment(date).format('YYYY-MM-DD') === moment(item.date).format('YYYY-MM-DD') &&
            item.trace.length
        ).length === 0
      )
    }
  }

  async mounted() {
    this.resultList = this.person.access
  }

  destroyed() {
    this.$message.closeAll()
  }

  localSearch(date: any) {
    this.resultList = []
    this.loading = true
    setTimeout(() => {
      if (date) {
        this.resultList = this.person.access.filter(
          (item: any) =>
            moment(date).format('YYYY-MM-DD') === moment(item.date).format('YYYY-MM-DD')
        )
      } else {
        this.resultList = this.person.access
      }
      this.loading = false
    }, 300)
  }

  backToTop() {
    const detail = this.$refs.detailNode as HTMLElement
    backTop(detail)
  }
  boxScroll() {
    const box = this.$refs.detailNode as Element
    if (!box) return false
    if (box.scrollTop > document.body.clientHeight) {
      this.isShowTop = true
    } else {
      this.isShowTop = false
    }
  }
}
