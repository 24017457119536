




















































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import pageTitle from '@/components/common/pageTitle.vue'
import CopyText from '@/components/common/CopyText.vue'
import FeatureImg from '@/components/dashboard/FeatureImg/index.vue'
import UploadImage from '@/components/common/UploadImage/index.vue'
import Verification from './compenents/IdentificationVerification/index.vue'
import VisitVideo from './video.vue'
import PersonDetail from './compenents/personDetail/index.vue'
import visitorDetail from './compenents/visitorDetail/index.vue'
import { parseGender } from '@/utils'
import { areaPath } from '@/utils/getAreaPath'
import SecurityText from '@/components/common/SecurityText/index.vue'
import moment from 'moment'
import { blobToJson } from '@/utils/helpers'

@Component({
  components: {
    pageTitle,
    CopyText,
    FeatureImg,
    UploadImage,
    VisitVideo,
    PersonDetail,
    SecurityText,
    Verification,
    visitorDetail
  }
})
export default class VisitorSearch extends Vue {
  @Ref() readonly videoNode: VisitVideo
  @Ref() filterHub: Vue
  @Ref() readonly verification: Verification
  private loading = false
  private noData = require('@/assets/images/dashboard/noData.png')
  private searchImageUrl = ''
  private filterData: AnyObj = {
    dateRange: [this.$moment().startOf('day'), this.$moment().endOf('day')]
  }

  private showFlag = false
  private resultList: any[] = []
  imageParam = {}
  private showImg = false
  private bigPictureUrl = ''
  private verifyDetail: any[] = []
  num = 1
  videoLoading = false
  currentIndex = 0
  visitorPerson: any = null
  showVisitorDetail = false

  get rootConfig() {
    return this.$store.state.config?.root
  }

  get root() {
    return this.rootConfig.id ? this.rootConfig.id : this.$store.state.user.scope
  }
  parseGender(g: string) {
    return parseGender(g)
  }
  getFirstAppearTime(person: AnyObj) {
    const time = person.firstAppearTime
    return time ? moment(time).format('YYYY-MM-DD HH:mm:ss') : '-'
  }

  private openVerify() {
    this.verification.show()
  }

  private selectPic(picture: any) {
    const { url, name, time, result, areas } = picture
    const areaName = areas
      .reduce((recc: string[], area: any) => {
        recc.push(area.name)
        return recc
      }, [])
      .join('/')

    this.verifyDetail = [
      { name: '姓名: ', value: name || '-' },
      { name: '核验时间: ', value: time ? this.$moment(time).format('YYYY-MM-DD HH:mm:ss') : '-' },
      { name: '核验结果: ', value: result ? '通过' : '未通过' },
      { name: '所属区域: ', value: areaName || '-' }
    ]
    this.searchImage(url, false)
  }

  private searchImage(val: string, clear = true) {
    if (clear) {
      this.verifyDetail = []
    }
    this.searchImageUrl = val
    this.getParams()
  }
  private dateFilter() {
    this.getParams()
  }
  private getAreas(val: any[]) {
    this.areaIds = val
    this.getParams()
  }

  private async getParams() {
    if (!this.searchImageUrl) return
    const {
      areaIds,
      dateRange: [startTime, endTime]
    } = this.filterData
    if (this.searchImageUrl.indexOf('base64,') > -1) {
      this.imageParam = {
        ['data']: this.searchImageUrl.split(',')[1]
      }
    } else {
      this.imageParam = { ['url']: this.searchImageUrl }
    }
    const params = {
      areaIds: areaIds && areaIds.length > 0 ? areaIds : [this.root],
      startTime,
      endTime
    }
    if (Object.keys(this.imageParam).length > 0) {
      this.loading = true
      this.resultList = []
      Object.assign(params, { images: [this.imageParam] })
      await this.getPersons(params)
    }
  }
  private async getPersons(params: any) {
    // 以图搜图
    const { data } = await this.$api.v2.getRealtyBatchPersons({ targets: [params] })
    if (data.data && data.data.length > 0) {
      const res = data.data[0]
      let list = []
      if (res.access && res.access.length > 0) {
        const { token, access } = res
        list.push({ token, level: 'extra-high', access })
      }
      if (res.similar && res.similar.length > 0) {
        res.similar.map((i: any) => (i.level = 'high'))
        list = [...list, ...res.similar]
      }
      list.forEach(async (item: any) => {
        const lastAccess = item.access[item.access.length - 1]
        item.firstAppearTime = item.access[0].trace[0].time
        item.lastAppearTime = lastAccess.trace[lastAccess.trace.length - 1].time
        item.targetImageUrl = this.searchImageUrl
        const resp = await this.$api.v2.getCapturesSearch({
          ids: [item.access[0].trace[0].captureId.toString()]
        })
        const r = resp.data.data
        if (r && r.length > 0) {
          item = {
            ...item,
            ...{
              firstAppearImageUrl: r[0].imageUrl,
              firstAppearSceneImageUrl: r[0].sceneImageUrl
            }
          }
        }
        this.resultList = [...this.resultList, item]
      })
    }
    this.loading = false
  }

  private async lookVideo(person: AnyObj, index: number) {
    if (this.videoLoading) return
    this.currentIndex = index
    this.videoLoading = true
    const resp = await this.$api.base.getRealtyPlaybacks(person.token)
    this.videoLoading = false
    if (resp.status === 200) {
      if (resp.data && resp.data.type === 'application/json') {
        const data: any = await blobToJson(resp.data)
        if (data.error && data.error.description) {
          this.$message({
            message: data.error.description,
            type: 'error'
          })
        }
      } else {
        this.showFlag = true
        let areaList = []
        const deviceId = resp.headers['device-id']
        if (deviceId) {
          const res = (await this.$api.base.getDevicesAreaPath(deviceId)).data
          areaList = res.data
        }
        const obj = {
          area: areaPath(areaList),
          src: URL.createObjectURL(new Blob([resp.data], { type: 'video/mp4' })),
          startTime: resp.headers['capture-time']
        }

        if (this.videoNode) this.videoNode.open([obj])
      }
    } else if (resp.data.error && resp.data.error.message === 'resource not found') {
      this.$alert('无首次到访视频到访视频', '提示', {
        confirmButtonText: '确定'
      })
    }
  }
  private getPersonDetail(row: any) {
    this.visitorPerson = { ...row }
    this.showVisitorDetail = true
  }
  sceneImageClick(url: string) {
    this.showImg = true
    this.bigPictureUrl = url
  }
}
